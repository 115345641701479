@tailwind base;
@tailwind components;
@tailwind utilities;


.container{
    padding: 1rem 3rem;
}

@font-face {
    font-family: ultra;
    src: url("./ttf/kiddosy-kiddosy-regular-400.ttf");
}

.ultra{
    font-family: ultra;
    font-weight: 300;
}

